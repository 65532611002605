<template>
  <div id="dashboard" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row>
        <!--VACACIONES-->
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-card color="#f2f2f2" class="cardDirectory">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span class="white--text text--center mt-n3 mb-n2">
                Vacaciones
              </span>
            </v-card-title>
            <v-card class="mt-n1">
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/enjoy" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Días por disfrutar</v-card-subtitle>
                      <v-card-text>{{ diasPorDisfrutar }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/reportAnnual" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Disfrutados en el año</v-card-subtitle>
                      <v-card-text class="mt-1">{{
                        disfrutadosPorAnio
                      }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/reportMonth" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Disfrutados en el mes</v-card-subtitle>
                      <v-card-text class="mt-n1">{{
                        disfrutadosPorMes
                      }}</v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/request" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle class="mb-n2"
                        >Solicitados por disfrutar</v-card-subtitle
                      >
                      <v-card-text>{{ solicitados }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle class="mb-1"
                        >Días vencidos</v-card-subtitle
                      >
                      <v-card-text class="mt-4">{{ vencidos }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Siguiente mes</v-card-subtitle>
                      <v-card-text class="mt-n1">
                        {{ mesSiguiente }}</v-card-text
                      >
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
        <!--INVENTARIO PERSONAL-->
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-card color="#f2f2f2" class="cardDirectory">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span class="white--text text--center mt-n3">
                Inventario Personal
              </span>
            </v-card-title>
            <v-card>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/areasPositions" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Posiciones</v-card-subtitle
                      ><v-card-text>{{ inventarioPosiciones }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/template" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Plantilla</v-card-subtitle
                      ><v-card-text>{{ inventarioPlantilla }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/vacants" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle>Vacantes</v-card-subtitle>
                      <v-card-text>{{
                        inventarioVacantes
                      }}</v-card-text></v-card
                    >
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/unemployed" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle>Bajas en el mes</v-card-subtitle>
                      <v-card-text>{{ inventarioBajas }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card to="/newEmployees" height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle>Altas en el mes</v-card-subtitle
                      ><v-card-text>{{ inventarioAltas }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle
                        >Vencimiento de contratos</v-card-subtitle
                      >
                      <v-card-text>{{ inventarioVencimiento }}</v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
        <!--AUSENTISMO LABORAL-->
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-card
            color="#f2f2f2"
            class="cardDirectory"
            to="/absenteeismReports"
          >
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span class="white--text text--center mt-n3">
                Incidencias Laborales
              </span>
            </v-card-title>
            <v-card>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex
                    v-bind="{ [`xs4`]: true }"
                    v-for="causa in causas"
                    :key="causa.id"
                  >
                    <v-card
                      :to="/absenteeism/ + causa.id"
                      :height="tam"
                      :max-height="tam"
                    >
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle class="pb-0">{{
                        causa.causa
                      }}</v-card-subtitle
                      ><v-card-text class="pa-0">{{
                        causa.cantidad
                      }}</v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!--y-->
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-card color="#f2f2f2" class="cardDirectory">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span class="white--text text--center mt-n3"
                >Métricas de personal</span
              >
            </v-card-title>
            <v-card>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Número de mujeres</v-card-subtitle
                      ><v-card-text>{{ mujeres.numero }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Porcentaje de mujeres</v-card-subtitle
                      ><v-card-text>{{ mujeres.porcentaje }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle> Edad prom. mujeres</v-card-subtitle>
                      <v-card-text>{{ mujeres.promedio }}</v-card-text></v-card
                    >
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Número de hombres</v-card-subtitle
                      ><v-card-text>{{ hombres.numero }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Porcentaje de hombres</v-card-subtitle
                      ><v-card-text>{{ hombres.porcentaje }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle> Edad prom. hombres</v-card-subtitle>
                      <v-card-text>{{ hombres.promedio }}</v-card-text></v-card
                    >
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
        <!--CUMPLEAÑOS Y CELEBRACIONES-->
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-card color="#f2f2f2" class="cardDirectory">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span class="white--text text--center mt-n3">
                Cumpleaños y Celebraciones
              </span>
            </v-card-title>
            <v-card>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs12`]: true }">
                    <v-card height="245">
                      <v-carousel
                        height="245"
                        continuous
                        cycle
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item
                          v-for="cel in celebraciones"
                          :key="cel.id"
                        >
                          <v-sheet color="amber lighten-4" height="100%" tile>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                            >
                              <v-col
                                cols="12"
                                xl="12"
                                md="12"
                                sm="12"
                                class="mt-n4 text--disabled"
                                ><h2>
                                  {{ cel.descripcion.substr(0, 14) }}
                                </h2></v-col
                              >
                              <v-col
                                cols="12"
                                xl="12"
                                md="12"
                                sm="12"
                                class="mt-n12 mb-n6 text--disabled"
                                ><h1>
                                  {{
                                    cel.descripcion.substr(
                                      14,
                                      cel.descripcion.length
                                    )
                                  }}
                                </h1></v-col
                              >
                              <v-col
                                cols="12"
                                xl="12"
                                md="12"
                                sm="12"
                                class="mt-n12 mb-n5 text--disabled"
                                ><h2>
                                  <span
                                    v-if="
                                      cel.descripcion.includes('Cumpleaños')
                                    "
                                    ><i class="fas fa-birthday-cake"></i
                                  ></span>
                                  {{ cel.fecha.substr(0, 10) }}
                                  <span
                                    v-if="
                                      cel.descripcion.includes('Cumpleaños')
                                    "
                                    ><i class="fas fa-birthday-cake"></i
                                  ></span></h2
                              ></v-col>
                            </v-row>
                          </v-sheet>
                        </v-carousel-item>
                      </v-carousel>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
        <!--NOM035-->
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-card color="#f2f2f2" class="cardDirectory" to="/reports">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span class="white--text text--center mt-n3"> NOM-035 </span>
            </v-card-title>
            <v-card>
              <v-container fluid grid-list-md>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear>
                      <v-card-subtitle>Riesgo general</v-card-subtitle
                      ><v-card-text>{{ puntajeFinal }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs8`]: true }">
                    <v-card :class="color3" height="115">
                      <v-card-subtitle style="color: white !important">{{
                        calificacionFinal
                      }}</v-card-subtitle
                      ><v-card-text style="color: white !important">
                        <span
                          style="color: white"
                          v-if="calificacionFinal == 'Muy alto'"
                          >Se requiere realizar el análisis de cada categoría y
                          dominio para establecer las acciones de intervención
                          apropiadas, mediante un Programa de intervención que
                          deberá incluir evaluaciones específicas1, y contemplar
                          campañas de sensibilización, revisar la política de
                          prevención de riesgos psicosociales y programas para
                          la prevención de los factores de riesgo psicosocial,
                          la promoción de un entorno organizacional favorable y
                          la prevención de la violencia laboral, así como
                          reforzar su aplicación y difusión.</span
                        >
                        <span
                          style="color: white"
                          v-if="calificacionFinal == 'Alto'"
                          >Se requiere realizar un análisis de cada categoría y
                          dominio, de manera que se puedan determinar las
                          acciones de intervención apropiadas a través de un
                          Programa de intervención, que podrá incluir una
                          evaluación específica1 y deberá incluir una campaña de
                          sensibilización, revisar la política de prevención de
                          riesgos psicosociales y programas para la prevención
                          de los factores de riesgo psicosocial, la promoción de
                          un entorno organizacional favorable y la prevención de
                          la violencia laboral, así como reforzar su aplicación
                          y difusión.</span
                        >
                        <span
                          style="color: white"
                          v-if="calificacionFinal == 'Medio'"
                          >Se requiere revisar la política de prevención de
                          riesgos psicosociales y programas para la prevención
                          de los factores de riesgo psicosocial, la promoción de
                          un entorno organizacional favorable y la prevención de
                          la violencia laboral, así como reforzar su aplicación
                          y difusión, mediante un Programa de
                          intervención.</span
                        >
                        <span
                          style="color: white"
                          v-if="calificacionFinal == 'Bajo'"
                          >Es necesario una mayor difusión de la política de
                          prevención de riesgos psicosociales y programas para:
                          la prevención de los factores de riesgo psicosocial,
                          la promoción de un entorno organizacional favorable y
                          la prevención de la violencia laboral.</span
                        >
                        <span
                          style="font-size: 0.85em; color: white"
                          v-if="calificacionFinal == 'Nulo o despreciable'"
                          >El riesgo resulta despreciable por lo que no se
                          requiere medidas adicionales.</span
                        >
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle>Encuestas requeridas</v-card-subtitle>
                      <v-card-text>{{ encuestas }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle>Encuestas en Proceso</v-card-subtitle
                      ><v-card-text>{{ encuestasProceso }}</v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex v-bind="{ [`xs4`]: true }">
                    <v-card height="115">
                      <v-progress-linear
                        color="orange"
                        class="mb-n3"
                      ></v-progress-linear
                      ><v-card-subtitle>Encuestas completadas</v-card-subtitle>
                      <v-card-text>{{ completadas }}</v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      inventarioPosiciones: 0,
      inventarioPlantilla: 0,
      inventarioAltas: 0,
      inventarioBajas: 0,
      inventarioVacantes: 0,
      inventarioVencimiento: 0,
      empleados: 0,
      area: 0,
      sucursal: 0,
      encuestas: 0,
      enterprise: localStorage.empresaIdGlobal,
      encuestas: 0,
      diasPorDisfrutar: 0,
      disfrutadosPorAnio: 0,
      disfrutadosPorMes: 0,
      solicitados: 0,
      vencidos: 0,
      mesSiguiente: 0,
      puntajeFinal: 0,
      categoria: [],
      dominio: [],
      calificacionFinal: 0,
      puntuajeFinal: 0,
      encuestasProceso: 0,
      completadas: 0,
      causasAux: [],
      causas: [],
      mujeres: {},
      hombres: {},
      celebraciones: [],
      model: 0,
    };
  },
  methods: {
    /*Obtiene los indicadores de NOM-035*/
    indicadores() {
      this.show = true;
      axios
        .post(
          Server + "/climaLaboralResultados/consulta-calificacion-preguntas",
          {
            EmpresaId: parseInt(this.enterprise),
            NumeroEmpleados: parseInt(this.empleados),
            EncuestasRequeridas: parseInt(this.encuestas),
            Guia: parseInt(2),
            CategoriaId: this.categoria.id,
            DominioId: this.dominio.id,
            AreaId: this.area,
            SucursalId: this.sucursal,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.puntajeCategoria = response.data.puntajeCategoria;
          this.puntajeDominio = response.data.puntajeDominio;
          this.calificacionCategoria = response.data.calificacionCategoria;
          this.calificacionDominio = response.data.calificacionDominio;
          (this.calificacionFinal = response.data.calificacionFinal),
            (this.puntajeFinal = response.data.puntajeFinal);
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerVacaciones() {
      this.show = true;
      axios
        .get(Server + "/vacaciones/reporte-dashboard/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          
          this.diasPorDisfrutar = response.data.diasPorDisfrutar;
          this.disfrutadosPorAnio = response.data.disfrutadosPorAnio;
          this.disfrutadosPorMes = response.data.disfrutadosPorMes;
          this.solicitados = response.data.solicitados;
          this.vencidos = response.data.vencidos;
          this.mesSiguiente = response.data.mesSiguiente;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Obtiene los indicadores de las encuentas realizadas en la empresa*/
    obtenerEncuesta() {
      this.show = true;
      axios
        .post(
          Server + "/catalogosNom35/numero-empleados",
          {
            EmpresaId: this.enterprise,
            AreaId: this.area,
            SucursalId: this.sucursal,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.encuestasProceso = response.data.encuestasProceso;
          this.completadas = response.data.encuestasCompletadas;
          this.empleados = response.data.numeroEmpleados;
          this.encuestas = response.data.encuestasProceso;
          if (this.empleados > 50) {
            this.encuestas = Math.round(
              (0.9604 * this.empleados) /
                (0.0025 * (this.empleados - 1) + 0.9604)
            );
            this.show = false;
          } else {
            this.encuestas = this.empleados;
            this.show = false;
          }
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Obtiene Relacion de Empleados por empresa*/
    obtenerInventario() {
      this.show = true;
      axios
        .get(Server + "/dashboard/inventario-personal/" + this.enterprise, {
          headers: {
            //Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.inventarioPosiciones = response.data.posiciones;
          this.inventarioPlantilla = response.data.plantilla;
          this.inventarioVacantes = response.data.vacantes;
          this.inventarioAltas = response.data.altasMes;
          this.inventarioBajas = response.data.bajasMes;
          this.inventarioVencimiento = response.data.contratosVencidos;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Obtiene metricas de Incidencias Laborales*/
    causasAusentismo() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-causas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.causasAux = response.data;
          if (this.causasAux.length != 0) {
            this.show = true;
            this.causasAux.forEach((causa, index) => {
              axios
                .get(
                  Server + "/dashboard/ausentismo/total-por-causa/" + causa.id,
                  {
                    headers: {
                      Authorization: localStorage.token,
                    },
                  }
                )
                .then((response) => {
                  this.causas.push({
                    id: causa.id,
                    causa: causa.causa,
                    cantidad: response.data.totalCausa,
                  });
                  this.causas.sort(function (a, b) {
                    if (a.causa > b.causa) {
                      return 1;
                    }
                    if (a.causa < b.causa) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  });
                  this.tam = 180 / (this.causas.length / 3);
                  this.show = false;
                })
                .catch((e) => {
                  console.log(e);
                  this.show = false;
                  if (e.response.status == 401) {
                    this.expiration = true;
                  }
                });
            });
          }
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    /*Obtiene las metricas del personal de la empresa*/
    metricas() {
      axios
        .get(Server + "/dashboard/metricas-personal/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.mujeres.numero = response.data.numeroMujeres;
          this.mujeres.porcentaje = response.data.porcentajeMujeres + "%";
          this.mujeres.promedio = response.data.edadPromedioMujeres;
          this.hombres.numero = response.data.numeroHombres;
          this.hombres.porcentaje = response.data.porcentajeHombres + "%";
          this.hombres.promedio = response.data.edadPromedioHombres;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    celebracionesDash() {
      this.show = true;
      axios
        .get(Server + "/dashboard/celebraciones/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.celebraciones = response.data;
          for(var i=0;i<this.celebraciones.length;i++){
            this.celebraciones[i].id=i+1;
          }
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  computed: {
    color3() {
      return {
        "red darken-1": this.calificacionFinal == "Muy alto",
        "orange darken-1": this.calificacionFinal == "Alto",
        "yellow lighten-3": this.calificacionFinal == "Medio",
        "green accent-3": this.calificacionFinal == "Bajo",
        blue: this.calificacionFinal === "Nulo o despreciable",
      };
    },
  },
  created() {
    this.obtenerEncuesta();
    this.indicadores();
    this.obtenerVacaciones();
    this.obtenerInventario();
    this.causasAusentismo();
    this.metricas();
    this.celebracionesDash();
  },
};
</script>
<style scoped>
.md-theme-default a:not(.md-button) {
  color: white !important;
}
</style>